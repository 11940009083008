body {
  --primary-text-color: rgb(18, 30, 25);
  --secondary-text-color: rgb(206, 208, 216);
  --default-background-color: white;
  --product-card-background-color: white;
  --black-color: black;
  --product-card-title-color: rgb(39, 39, 41);
  --upgrade-button-color: rgb(56, 126, 246);
  --create-product-primary-button-color: #4a4ad8;
  --create-product-primary-button-hover-color: #3d3dcd;
  --create-product-primary-button-border-color: #2e2ec2;
  --blue-text-color: var(--create-product-primary-button-color);
  --unwanted-button-color: rgb(204, 63, 48);
  --billing-primary-text-color: #2e2ec2;
  --billing-promotional-text-color: green;
  --active-text-color: var(--billing-promotional-text-color);
  --desactivated-text-color: var(--unwanted-button-color);
  --switch-height-container-size: 20px;
  --switch-width-container-size: 36px;
  --create-product-secondary-button-color: white;
  --create-product-secondary-button-hover-color: #f4f4f5;
  --create-product-secondary-button-border-color: #d4d4d8;
  --bottom-background-color: #fafafa;
  --menu-icon-color: #000000;
  --category-secondary-text-color: #71717a;
  --google-button-background-color: var(
    --create-product-secondary-button-hover-color
  );
  --gray-icon-color: #d4d4d8;
  --colored-action-text-color: #2e2ec2;
  --white-hover-color: #f4f4f5;
  --white-svg-background: white;
  --chart-background-color: var(--purple-color);
  --border-color: #e4e4e7;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--default-background-color);
  max-width: 100%;
  --borderRadiusCard: 4px;

  --purple-color: rgb(139, 61, 255);
  --primary-purple-color-transparent: rgba(139, 61, 255, 0.5);
  --primary-border-color-f-transparent: rgba(255, 255, 255, 0.1);
  --primary-purple-color-hover-transparent: rgb(119, 49, 216, 0.5);
  --purple-color-transparent: rgba(165, 112, 255, 0.15);
  --purple-color-hover: rgb(119, 49, 216);
  --sidebar-not-main-background-color: white;
  --sidebar-not-main-width: 320px;
  --sidebar-toolbar-not-main-width: 64px;
  --mobile-topbar-height: 56px;
  --mobile-bottom-navigation-height: 70px;
  --black-color: rgb(63, 66, 22);
  --black-icon-color: rgb(63, 66, 22);
  --mobile-topbar-nav-background-color: linear-gradient(
    90deg,
    rgba(139, 61, 255, 1) 0%,
    rgba(79, 0, 255, 1) 49%,
    rgba(0, 212, 255, 1) 100%
  );
  --white-button-hover-color-w-transparent-background: rgba(64, 87, 109, 0.2);
  --white-button-hover-color: rgba(64, 87, 109, 0.07);
  --icon-selected-color: white;
  --icon-selected-color: white;
  --icon-size: 22px;
  --white-color: white;
  --sidebar-section-background-color: white;
  --sidebar-section-border-radius: 8px;
  --black-text-color: rgb(63, 66, 22);
  --white-text-color: white;
  --button-border-radius: 8px;
  --drawer-title-font-size: 18px !important;
  --drawer-padding: 20px !important;
  --drawer-nav-padding: 10px !important;
  --drawer-nav-icon-size: 20px;
  --bottom-navigation-box-shadow: 0px 0px 0px 1px rgba(64, 87, 109, 0.04),
    0px 6px 20px -4px rgba(64, 87, 109, 0.3);
  --lateral-menu-background-color: linear-gradient(180deg, #f5f0fe, #eef5fc);
  --header-card-background-color: linear-gradient(
    90deg,
    rgba(139, 61, 255, 1) 0%,
    rgba(79, 0, 255, 1) 49%,
    rgba(0, 212, 255, 1) 100%
  );
  --header-card-background-border-radius: 12px;
  --divider-color: #f3f3f3;
}

header {
  background-color: white !important;
  color: rgb(101, 101, 103) !important;
}

.createNewProductButton {
  background-color: var(--create-product-primary-button-color) !important;
}

.navbarItem {
  color: rgb(39, 39, 41) !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 1536px) {
  .MuiContainer-root {
    max-width: auto !important;
  }
}