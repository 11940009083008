.Overlay {
  position: absolute;
  top: 0px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 2px);
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 41em;
  z-index: 1000;
}

.Modal {
  position: absolute;
  left: 0px;
  bottom: 0px;
  background-color: var(--background-color);
  width: 100%;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  border-radius: 0px 0px 40em  40em;
}
