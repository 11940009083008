.modalBackdrop {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40em;
    z-index: 1000;
  }
  
  .modalContent {
    border-radius: 40em;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .modalBody {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  