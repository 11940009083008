.rateButton {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 255px;
    font-size: 16px;
    background-color: var(--reverse-background-color);
    color: var(--reverse-text-color);
    border: none;
    border-radius: 100px;
    font-family: var(--font-family);
    cursor: pointer;
    display: flex !important;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
    padding: 16px 32px;
  }
  
  .rateButton > p {
    margin: 0px;
    font-weight: 600;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }